import React from "react"
import Layout from "../../components/layout"
import "../works.css"
import WorksFull from "../../components/worksFull"
import Contact from "../../components/contact"

export default () => (
  <Layout
    lang="fr"
    pageUrl="/works"
    seoTitle="Projets: découvrez nos dernières réalisations web et digital"
    seoDescription="Découvrez une sélection de nos dernières réalisations. Donnez vie aux idées de nos client, c'est ce qu'on fait le mieux."
  >
    <div id="works-full-background">
      <WorksFull lang="fr" />
    </div>
    <div id="contact-worksfull-background">
      <Contact lang="fr" />
    </div>
  </Layout>
)
